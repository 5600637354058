import React from 'react'
import { AiFillDollarCircle } from 'react-icons/ai'
function Dolla({size}) {
  return (
   <>
    <AiFillDollarCircle className={size}/>
   </>
  )
}

export default Dolla