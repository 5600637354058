import React from 'react';
import {SlScreenSmartphone} from "react-icons/sl"
const ScreenPhone = ({size}) => {
    return (
        <>
            <SlScreenSmartphone  className={size} />

        </>
    );
}

export default ScreenPhone;
