import React, { useState } from "react";
// import { Link, NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Calendar,
  CheckCircleFill,
  Eye,
  FillHeart,
  Map,
  OutlineHeart,
  Share,
  Squares,
} from "../component/icons/index";

import DisclimerProduct from "../component/disclimer-product/DisclimerProduct";
import { FacebookShareButton } from "react-share";
import { getPostDate } from "../utils/getPostDate";
const currentUrl = typeof window !== "undefined" ? window.location.href : "";
const guidlineList = [
  {
    id: 1,
    tittle: "Ask question to be clear on vehicle details",
  },
  {
    id: 2,
    tittle: "be aware of time & when going for vehicle test-drive",
  },
  {
    id: 3,
    tittle: "don't make a payment without verifying the credentails ",
  },
];

const Detail = () => {
  // const
  const [isLove, setIsLove] = useState(false);
  const handleLove = () => {
    setIsLove((isLove) => !isLove);
  };

  const [ToggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const getActiveClass = (index, className) =>
    ToggleState === index
      ? className
      : "border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300";

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`${currentUrl}`} />
        <meta
          property="og:title"
          content="SEYHA-SHOP Buy and Sell Everything In Cambodia"
        />
        <meta
          property="og:description"
          content="Sell your 2nd hand items on seyhashop.pages.dev, the Cambodia #1 buy and sell website. Post free online classified ads of your property, real estate, home, land, car, motorcycle, mobile phone, computer, electronic, furniture, job, service and more."
        />
        <meta
          property="og:keywords"
          content="buy and sell, online shopping, shopping, shopping in Cambodia, real estate, classified, listing, post free ads, classified ads, property, home, land, car, mobile phone, computer, electronic, furniture, job, service, motorcycle, product price"
        />
        <meta
          property="og:image"
          content="https://seyhashop.pages.dev/01.webp"
        />
      </Helmet>
      <div className="w-full py-5 px-2 xl:px-0">
        <div className="flex flex-col lg:flex-row  gap-5">
          <aside className=" w-full lg:w-[77%]">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex flex-col gap-2.5 p-5 bg-white rounded-lg">
                <div className="flex justify-between">
                  <h3 className="text-xl font-[600]">{" jhjhlkjjgyl"}</h3>
                  <div className="bg-white p-1 rounded-full shadow w-fit">
                    <span
                      title="Add to Favorites"
                      className="text-[28px] cursor-pointer"
                      onClick={handleLove}
                    >
                      {isLove ? (
                        <FillHeart color="text-button-red" />
                      ) : (
                        <OutlineHeart color="text-button-red" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex gap-5 mb-2">
                  <div className="flex items-center gap-2">
                    <Squares props="text-default text-[14px]" />
                    <p className=" capitalize text-[14px]">category</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar props="text-default text-[14px]" />
                    <p className="text-[14px]">July 01,2023 { getPostDate("2024-03-11") } </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Map props="text-default text-[14px]" />
                    <p className="text-[14px]"> location </p>
                  </div>
                  <div className="flex items-center justify-center gap-2">
                    <Eye props="text-default text-[14px]" />
                    <p className="">view</p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className=" bg-green  flex flex-row items-center justify-end gap-2 rounded-[25px] px-3 py-1.5">
                    <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                      <img
                        src="https://www.khmer24.com/v1.0.4/template/img/khmer24.gif"
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <span className="text-white text-[14px]">
                      {" "}
                      Name Account{" "}
                    </span>
                  </div>
                  <FacebookShareButton url={`${window.location.href}`}>
                    <span className="text-default">
                      {" "}
                      <Share props={"text-[16px]"} />{" "}
                    </span>
                  </FacebookShareButton>
                </div>
              </div>

              {/************* SLIDER *************/}

              <div className="p-5 bg-white rounded-lg w-full ">
                <div className="border-b">
                  <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                    <li className="mr-2" onClick={() => toggleTab(1)}>
                      <button
                        className={`inline-block p-4 ${getActiveClass(
                          1,
                          "text-button-blue border-b-2 border-button-blue"
                        )}`}
                      >
                        Details
                      </button>
                    </li>
                    <li className="mr-2" onClick={() => toggleTab(2)}>
                      <button
                        className={`inline-block p-4 ${getActiveClass(
                          2,
                          "text-button-blue border-b-2 border-button-blue"
                        )}`}
                      >
                        Description
                      </button>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    className={`px-5 py-3 rounded-lg bg-white ${
                      ToggleState === 1 ? "" : "hidden"
                    }`}
                  >
                    <div className="flex flex-wrap">
                      {/* {Object.entries(product.details).map(([key, val]) => (
                        <div key={key} className="w-1/2 py-2">
                          <div className="w-full flex">
                            <div className="w-2/5 capitalize">
                              <p>{key}</p>
                            </div>
                            <div className="w-3/5 capitalize">
                              <p>{val}</p>
                            </div>
                          </div>
                        </div>
                      ))} */}
                    </div>
                  </div>
                  <div
                    className={`p-5 rounded-lg bg-white ${
                      ToggleState === 2 ? "" : "hidden"
                    }`}
                  >
                    <p className="text-sm text-default">
                      {/* {product.description} */}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full bg-white p-5 flex flex-col gap-2.5 rounded-lg">
                <h3 className="font-[600] text-[#0C9000]">Buyer Guidline</h3>
                <div className="w-full border-b bg-black" />
                {guidlineList.map((item, index) => (
                  <div key={index} className=" flex items-center gap-2.5">
                    <CheckCircleFill size="text-[#2550A6]" />
                    <p className="">{item.tittle}</p>
                  </div>
                ))}
              </div>

              <div className="bg-white rounded-lg w-full p-5">
                <DisclimerProduct />;
              </div>

              <div className="">
                <h3 className="mb-5 font-[600]">Similar Products</h3>
                <div className="grid grid-cols-4 gap-5 rounded-lg ">
                  {/* {products.map((item, index) => {
                    return (
                      <ProductItem key={index} {...item} {...item.details} />
                    );
                  })} */}
                </div>
              </div>
            </div>
          </aside>

          <main className=" w-full lg:w-[23%] flex flex-col gap-5">
            <div className="w-full p-5 bg-white rounded-lg">
              <div className="mb-5 flex justify-between items-center">
                <h3 className=" text-title font-[600] text-xl">Page review</h3>
                <p className="text-button-blue"> Explore more </p>
              </div>
            </div>

            <div className="w-full flex flex-col gap-5 p-5 bg-white rounded-lg">
              <h3 className="text-title font-[600]">Other Pags</h3>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Detail;
