import React from "react";
import { Routes, Route } from "react-router-dom";
import MyProfileLayout from "./MyProfileLayout";
import PostProduct from "./PostProduct";
import PostService from "./PostService";
import PagePostJob from "./PagePostJob";
import PostJobs from "./PostJobs";
import JopUpdate from "./JopUpdate";

function MyProfileRoute() {
  return (
    <>
      <Routes>
        <Route element={<MyProfileLayout />}>
          <Route index element={<PostProduct />} />
          <Route path="/service" element={<PostService />} />
          <Route path="/job" element={<PagePostJob />} />
          <Route path="/postjob" element={<PostJobs />} />
          <Route path="/jobupdate/:idjob" element={<JopUpdate />} />
        </Route>
      </Routes>
    </>
  );
}

export default MyProfileRoute;
