import React,{useState} from "react";
import { ApiRequest } from "../../ApiRequest";
import { useParams } from "react-router-dom";

function ResetPassword() {

 
  const [ password, setPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const {resettokencode}= useParams();

  const handleResetPassword = async () => {
    try {
      const response = await ApiRequest(
        "POST",
        `api/v1/password/reset/${resettokencode}`,
        { password, comfirmPassword }
      );
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className=" w-full flex justify-center items-center h-[80vh] p-5">


    <div className=" w-full md:w-[500px]  px-10 py-8 shadow-lg rounded-xl bg-white ">
      <h5 className="mb-10 text-2xl font-semibold tracking-tight text-title text-center">
        Reset New Password
      </h5>

      <div>
      <div className="mb-6 text-left">
          <label
            htmlFor="code"
            className="block mb-2 text-sm font-medium text-default dark:text-default"
          >
           Code
          </label>
          <input
            type="code"
            id="email"
            className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            placeholder="code"
            required
            // ref={emailRef}
            name="email"
            value={resettokencode}
            // onChange={handleChange}
          />
        </div>
        <div className="mb-6 text-left">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-default dark:text-default"
          >
            New password
          </label>
          <input
            type="password"
            id="password"
            className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            placeholder="New password"
            required
            // ref={emailRef}
            name="email"
            // value={formData.email}
            onChange={(e)=>{setPassword(e.target.value)}}
          />
        </div>

        <div className="mb-2 text-left">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-default dark:text-default"
          >
          Comfirm password
          </label>
          <input
            type="password"
            id="password"
            placeholder="comfirm password"
            className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            required
            // ref={passwordRef}
            name="password"
            // value={formData.password}
            onChange={(e)=>{setComfirmPassword(e.target.value)}}
          />
        </div>

        {/* <Link to={'/forgot/password'} className=" text-blue-600" > Forgot your password ?</Link> */}

        {/* <div className="flex items-start mb-6">
        <div className="flex items-center h-5">
          <input
            id="remember"
            type="checkbox"
            value=""
            className="w-4 h-4 focus:accent-button-blue border-default rounded-xl"
          />
        </div>
        <label
          htmlFor="remember"
          className="ml-2 text-sm font-medium text-default dark:text-default"
        >
          Remember me
        </label>
      </div> */}
        <div className=" flex justify-between mt-6">
          <button 
         onClick={()=> handleResetPassword()}
          className="bg-button-blue w-fit mb-3 py-2 px-5 rounded-lg font-normal text-white bg-blue-800">
            LOGIN
          </button>
          <div className="w-fit mb-3 py-2 px-5 rounded-md font-normal text-green-700 ">
            {/* <Link to={"/signup"}> Have not account?</Link> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default ResetPassword;
