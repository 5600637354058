import React from 'react'
import {MdWorkOutline} from'react-icons/md'
function Position() {
  return (
    <>
        <MdWorkOutline/>
    </>
  )
}

export default Position