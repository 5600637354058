import React, { useEffect, useState } from "react";
// icon

// import IconCheck from'../components/icons/Check';
import IconFacebook from "../component/icons/Facebook";
import IconTelegram from "../component/icons/Telegram";
import IconLinkIn from "../component/icons/LinkIn";
// import { Link } from 'react-router-dom';
// import JobItem from "../../components/job/JobItem";
import { Calendar, Dolla, Map } from "../component/icons/index";
import { useParams } from "react-router-dom";
import { ApiRequest } from "../ApiRequest";

function JobDetail() {
  const phoneNumber = "017431324"; // Replace this with your phone number
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const { id } = useParams();
  const [jobs, setJobs] = useState({});

  const fetchJobs = async () => {
    try {
      const response = await ApiRequest("GET", `api/v1/getone/job/${id}`);
      console.log(response.jobs[0]);
      setJobs(response.jobs[0]);
      // console.log(error);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="w-full py-5  flex flex-col gap-5 xl:px-0 px-2">
        {/* <JobItem /> */}
        <div className="w-full flex lg:flex-row flex-col gap-5">
          <div className="w-full lg:w-[30%]  xl:w-[23%] h-fit flex flex-col gap-[20px] rounded-[10px] overflow-hidden bg-white">
            <div className="w-full h-[270px] bg-default relative">
              <img src="" alt="" className="w-full h-full object-cover" />
              <div className="absolute bottom-5 left-5">
                <span className="text-[30px] font-[700] text-white">BMN</span>
                <img src="" alt="" />
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 px-[15px]">
              <span className="text-white text-[14px] font-[500] py-[10px] px-[12px] rounded-[25px] bg-button-blue w-fit">
                APPLY NOW
              </span>
              <span className="text-default  text-[14px] font-[500] py-[10px] px-[12px] rounded-[25px] bg-[#dddd] w-fit">
                SAVE
              </span>
              <span className="text-default  text-[14px] font-[500] py-[10px] px-[12px] rounded-[25px] bg-[#dddd] w-fit">
                SHARE
              </span>
            </div>
            <div className="flex flex-col gap-1 px-[20px]">
              <span className="text-default text-[16px] font-[600]">
                Address
              </span>
              <span className="text-default text-[16px] font-[400]">
                Phnom Penh , Building 36, St. 606 Corner 307, Sangkat Boeung Kak
                2, Khan Toul Kork, Phnom Penh, Cambodia
              </span>
            </div>
            <div className="flex flex-col gap-1 px-[20px]">
              <span className="text-default text-[16px] font-[600]">Email</span>
              <span className="text-default text-[16px] font-[400] mb-3">
                Email@gmail.com
              </span>
              <span
                onClick={() => handleCallClick()}
                className="bg-green text-white  text-[14px]  font-[500] py-[10px] px-[16px] rounded-[25px] bg-button-green w-fit"
              >
                Call Now
              </span>
            </div>
            <div className="flex flex-col gap-[10px] px-[20px]">
              <span className="text-default text-[16px] font-[600]">Media</span>
              <div className="flex flex-row gap-[10px]">
                <div className="flex justify-center items-center h-[50px] w-[50px] bg-button-blue rounded-full">
                  <IconFacebook props="text-white text-[20px]" />
                </div>
                <div className="flex justify-center items-center h-[50px] w-[50px] bg-[#0082CB] rounded-full">
                  <IconTelegram props="text-white text-[20px]" />
                </div>
                <div className="flex justify-center items-center h-[50px] w-[50px] bg-[#035583] rounded-full">
                  <IconLinkIn props="text-white text-[20px]" />
                </div>
              </div>
              <div className="w-full h-[150px] rounded-[10px] overflow-hidden mb-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31269.293172585534!2d104.8852213472128!3d11.5760958419954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31095144cbdbf311%3A0x2588e1ac1787eb64!2sWat%20Phnom%20Daun%20Penh!5e0!3m2!1sen!2skh!4v1685501564230!5m2!1sen!2skh"
                  title="Location "
                  className="w-full"
                ></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31269.293172585534!2d104.8852213472128!3d11.5760958419954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31095144cbdbf311%3A0x2588e1ac1787eb64!2sWat%20Phnom%20Daun%20Penh!5e0!3m2!1sen!2skh!4v1685501564230!5m2!1sen!2skh" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              </div>
            </div>
          </div>

          <div className="w-full h-fit lg:w-[70%] xl:w-[77%] bg-white rounded-[10px] p-1 lg:p-3 flex flex-col">
            <div className="p-[20px] flex flex-col gap-[10px]">
              <span className="text-default text-[20px] font-[500] uppercase">
                Job Descriptioon
              </span>
              <div dangerouslySetInnerHTML={{ __html: jobs.description }} />
              {/* <span className="text-[16px] font-[400]">
                BBC Media Action uses media and communication to help people
                take control of their lives and make informed decisions. We do
                this through our focus on health, governance and rights,
                resilience and humanitarian response, partnering with civil
                society, local media and governments to produce creative
                programmes in multi-media formats which inform and engage
                audiences and strengthen the media sector. We have been working
                in Cambodia since 2003 addressing a range of development issues
                including climate change adaptation, sexual and reproductive
                health, youth empowerment and economic security. We are seeking
                for one qualified candidate to fill the position of Senior
                Graphic Designer who will develop and deliver innovative and
                creative content and design templates, layouts and solutions for
                BBC Media Action outputs and brands with a particular focus on
                social media content. S/he will use her/his expertise to drive
                the creative efforts in the production of content and graphics
                and work with the production team to ensure that materials are
                delivered on time and in accordance with BBC Media Action’s
                editorial requirements and high creative standards.
              </span> */}
            </div>
            {/* Job position*/}
            <div className="p-[20px] flex flex-col gap-[10px]">
              <span className="text-default text-[20px] font-[500] uppercase">
                Job Position
              </span>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-5">
                <div className="flex flex-row gap-2">
                  <div className="pt-[10px]">
                    <Calendar styles={"text-[14px]"} />
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="text-[14px]"> Schedule </span>
                    <span className="text-[14px] font-[500] uppercase">
                      {jobs.subcategory}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="pt-[3.4px]">
                    <Dolla styles={"text-[16px]"} />
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="text-[14px] mb-2"> Salary </span>
                    <span className="text-[14px] font-[500] uppercase">
                      {jobs.salary} $
                    </span>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="pt-[3.4px]">
                    <Map styles={"text-[14px]"} />
                  </div>
                  <div className="flex flex-col justify-between">
                    <span className="text-[14px]">Location</span>
                    <span className="text-[14px] font-[500] uppercase">
                      {jobs.location}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Job requirement */}
            <div className="p-[20px] flex flex-col gap-[10px]">
              <span className="text-default text-[20px] font-[400] capitalize">
                Requirements
              </span>
              <div dangerouslySetInnerHTML={{ __html: jobs.requirement }} />

              {/* <span className="text-[16px] font-[400]">
                <li>Essential Knowledge, Skills and Experience</li>
                <li className="ml-5">
                  Extensive portfolio of consistently great work demonstrating
                  creative flair.
                </li>
                <li className="ml-5">
                  Passion for and understanding of graphic understanding.
                </li>
                <li className="ml-5">
                  Proven record as a social media digital content creator with a
                  highly developed visual sense and innovative ideas for digital
                  content.
                </li>
                <li className="ml-5">
                  Well-developed technical skills and experience using graphics
                  design and animation software
                  <p>including:</p>
                </li>
                <li className="ml-10">Adobe Illustrator</li>
                <li className="ml-10">Adobe Photoshop</li>
                <li className="ml-10">Adobe Premier</li>
                <li className="ml-10">Adobe AfterEffect</li>
                <li className="ml-10">Adobe InDesign</li>
                <li className="ml-5">
                  {" "}
                  Ability to work quickly and creatively under pressure and
                  deliver against set deadlines.
                </li>
                <li className="ml-5">
                  {" "}
                  Portfolio of design work demonstrating technical and creative
                  ability on different platforms.
                </li>
                <li className="ml-5">
                  {" "}
                  Experience of taking ideas from concept to finished product
                  whilst driving forward creativity.{" "}
                </li>
                <li className="ml-5">
                  {" "}
                  Excellent interpersonal skills with the ability to form and
                  maintain effective working relationships at all levels.{" "}
                </li>
                <li>Desirable Knowledge, Skills and Experience .</li>
                <li className="ml-5">
                  Good verbal and written English skills.
                </li>
              </span> */}
            </div>
            {/* duties */}
            <div className="p-[20px] flex flex-col gap-[10px]">
              <span className="text-default text-[20px] font-[400] uppercase">
                Duties
              </span>
              {/* 
              <li>
                {" "}
                Design and create artwork, illustrations and animations based on
                a brief and using the required design software for social media
                and TV outputs as well as outreach materials when required.
              </li>
              <li>
                Create innovative and appealing designs and layouts for the
                development of the Klahan9 brand and other BBC Media Action
                outputs.
              </li>
              <li>
                Have a thorough understanding of BBC branding and
                implementation.
              </li>
              <li>Provide creative inputs for content development.</li>
              <li>
                Keep across emerging trends in the Cambodian and global media
                and design sectors.
              </li>
              <li>
                Work under pressure to deliver project outputs quickly,
                efficiently and to a high standard.
              </li>
              <li>
                Share design expertise and creative solutions for the benefit of
                the audience
              </li>
              <li>
                Able to lead on key design projects, and creatively guide other
                colleagues.
              </li>
              <li>
                Understand and comply with the Safeguarding policy and the staff
                Code of Conduct.
              </li> */}

              <div dangerouslySetInnerHTML={{ __html: jobs.duties }} />
            </div>
          </div>
        </div>

        <div className="p-[20px] flex flex-col gap-[20px] bg-white">
          <span className="text-[20px] font-[500] text-default">
            Recomment Schoolarship
          </span>
          <div className="grid grid-cols-2 gap-[20px] w-full">
            {/* <JobItem />
            <JobItem />
            <JobItem />
            <JobItem /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDetail;
