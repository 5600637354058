import React, { Suspense, lazy } from "react";
// import FooterMobile from "./layout/FooterMobile";
import Loading from "./component/loading/Loading";
import Header from "./layout/Header";
import { Route, Routes } from "react-router-dom";
import Cars from "./page/Cars";
import Properties from "./page/Properties.js";
// import Footer from "./layout/Footer";
import Computers from "./page/Computers.js";
import Fashions from "./page/Fashions";
import Funitures from "./page/Funitures";
import Pets from "./page/Pets";
import Educations from "./page/Educations";
import Toys from "./page/Toys";
import Foods from "./page/Foods";
import SignIn from "./component/sign-in/SignIn";
import SignUp from "./component/sign-up/SignUp";

import Page404 from "./page/Page404.js";
import RequireAuth from "./utils/RequireAuth.js";
import MyAccountRoute from "./page/my-account/MyAccountRoute.js";
import RequireMyAcount from "./utils/RequireMyAccount.js";
import ResetPassword from "./component/resetpassword/ResetPassword.js";
import ForgotPassword from "./page/my-account/ForgotPassword.js";

import Detail from "./page/Detail.js";
import Jobs from "./page/Jobs.js";
import JobDetail from "./page/JobDetail.js";
import ServiceDetail from "./page/services/ServiceDetail.js";
import Services from "./page/Services.js";
import MyProfileRoute from "./page/my-profile/MyProfileRoute.js";

const Home = lazy(() => import("./page/Home"));

function App() {
  return (
    <>
      <Header />
      {/* <CategoryProducts/>  */}

      <div className="w-full xl:w-[1300px] m-auto ">
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cars-vehicles" element={<Cars />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/computers" element={<Computers />} />
            <Route path="/fashions" element={<Fashions />} />
            <Route path="/furniture" element={<Funitures />} />
            <Route path="/pets" element={<Pets />} />
            <Route path="/educations" element={<Educations />} />
            <Route path="/toys" element={<Toys />} />
            <Route path="/foods" element={<Foods />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service/detail/:id" element={<ServiceDetail />} />
            <Route path="/job/detail/:id" element={<JobDetail />} />
            <Route path="/detail/:id" element={<Detail />} />
            <Route
              path="/signin"
              element={
                <RequireAuth>
                  <SignIn />
                </RequireAuth>
              }
            />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/setting/*"
              element={
                <RequireMyAcount>
                  <MyAccountRoute />
                </RequireMyAcount>
              }
            />
            <Route
              path="/resetpassword/:resettokencode"
              element={<ResetPassword />}
            />
            <Route path="/forgot/password" element={<ForgotPassword />} />

            <Route path="/profile/:idUser/*" element={<MyProfileRoute />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default App;
