import React from "react";
import { MdOutlineReportProblem } from "react-icons/md";
// import Modal from '../../components/modal/Modal'
const DisclimerProduct = () => {
  // const [report, setReport] = useState(false);



  // useEffect(() => {
  //   // Apply or remove styles based on the popup state
  //   if (popup) {
  //     const scrollbarWidth =
  //       window.innerWidth - document.documentElement.clientWidth;

  //     // Apply negative margin to compensate for the scrollbar width
  //     document.body.style.marginRight = `${scrollbarWidth}px`;
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.marginRight = "";
  //     document.body.style.overflow = "auto";
  //   }
  // }, [popup]);

  // const PopupReport = () => {
  //   return (
  //     <div
  //       className={"bg-white rounded-[10px] px-[40px] py-[30px] fixed top-[50%] left-[50%] z-[60] flex flex-col gap-[30px] translate-x-[-50%] translate-y-[-50%] shadow-xl"}
  //     >
  //       <h3 className="text-[24px] text-center font-[500]">
  //         Report
  //       </h3>
  //       <form className="w-auto flex flex-col gap-1">
  //         <div className="flex flex-col gap-3 w-full">
  //           <label className="flex flex-row gap-3 items-center text-[16px]">
  //             <input type="checkbox" />
  //             <span>Product already sold</span>
  //           </label>
  //           <label className="flex flex-row gap-3 items-center text-[16px]">
  //             <input type="checkbox" />
  //             <span>Ad is duplicate</span>
  //           </label>
  //           <label className="flex flex-row gap-3 items-center text-[16px]">
  //             <input type="checkbox" />
  //             <span>Wrong category</span>
  //           </label>
  //           <label className="flex flex-row gap-3 items-center text-[16px]">
  //             <input type="checkbox" />
  //             <span>Fraud reason</span>
  //           </label>
  //           <label className="flex flex-row gap-3 items-center text-[16px]">
  //             <input type="checkbox" />
  //             <span>Seller not responding/phone unreachable</span>
  //           </label>
  //         </div>
  //         <div className="flex flex-col gap-2 ">
  //           <span> Something else!</span>
  //           <textarea
  //             className="h-[100px] border-[1px] border-solid border-button-blue outline-none rounded-[10px] px-2 py-1 placeholder:text-default"
  //             placeholder="Write here..."
  //           ></textarea>
  //         </div>
  //       </form>
  //       <div className="flex items-center justify-center">
  //         <button
  //           className="bg-button-blue text-white px-[16px] py-[10px] rounded-[10px]"
  //           onClick={() => {
  //             alert("hello");
  //           }}
  //         >
  //           Send
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }


  return (
    <div className="flex flex-col gap-2.5">
      <div className="w-full flex justify-between  ">
        <h3 className="font-bold text-red">Disclaimer</h3>
        <div className="flex items-center gap-1 text-red cursor-pointer" >
          <MdOutlineReportProblem className="text-red" />
          <span>Report</span>
        </div>
      </div>
      <div className="border-b" />
      <p className="text-default">
        We does not control the content posted by members and therefore assumes no responsibility and disclaims any liability for any consequence relating directly or indirectly to any action or inaction.
      </p>

      {/* {
        report && <Modal onClose={setReport}>  <PopupReport/> </Modal>
      }
         */}
       
    
    </div>
  );
};

export default DisclimerProduct;
