import React from 'react';
import {BsGrid} from "react-icons/bs"
const Squares = ({props}) => {
    return (
        <>
           <BsGrid className={props}  /> 
        </>
    );
}

export default Squares;
