import React, { useEffect, useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import { ApiRequest } from "../ApiRequest";
// import ProductItem from "../component/Item/ProductItem";
import ItemJob from "../component/item-job/ItemJob";
import ItemsList from "../component/menu/ItemsList";

function Jobs() {
  const [jobData, setJobData] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const fetchJobData = async () => {
    try {
      const jobData = await ApiRequest("GET", "api/v1/jobs");
      setJobData(jobData.jobs);
    } catch (error) {
      console.log(error);
    }
  };


  const fetchSubCategory = async () => {
    try {
      const response = await ApiRequest(
        "GET",
        "api/v1/subcategories?category=jobs"
      );
      setSubcategories(response.subcategories);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchJobData();
    fetchSubCategory();
  }, []);

  return (
    <div className="w-full flex flex-col xl:flex-row gap-5 py-5">
      <ItemsList />
      <div className="w-[23%] h-fit xl:flex flex-col gap-5 hidden ">
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Categories</span>
          <ul className="flex flex-col gap-2.5 pl-3 max-h-28 overflow-y-auto">
            {subcategories.map((item, index) => (
              <li
                key={index}
                className={`w-full text-sm capitalize cursor-pointer hover:text-button-blue `}
              >
                {item.name}
              </li>
            ))}
          </ul>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Types</span>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Locations</span>
          {/* <Text value={types} /> */}
        </div>
      </div>
      <div className={"xl:w-[77%] w-full  h-[100vh] bg-blue-200 "}>
        {/* <InfiniteScroll
          dataLength={product.length}
          next={handleLoadMore}
          hasMore={hasMore}
        > */}

        <div className="grid lg:grid-cols-2 xl:grid-cols-1  gap-5 w-full">
          {jobData.map((item) => (
            <ItemJob key={item.id} {...item} />
          ))}
        </div>
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
}

export default Jobs;
