import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from "../../ApiRequest";
import { useAuth } from "../../utils/auth";
import { useCookies } from "react-cookie";

// import axios from "axios";

function SignIn() {
  const { login } = useAuth();
  const [, setCookie] = useCookies(["user"]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      if (formData.email === "") {
        alert("Please input email");
        return;
      } else if (formData.password === "") {
        alert(" Please input password");
        return;
      }
      const response = await ApiRequest("POST", "api/v1/login", formData);
      if (response.success) {
        setCookie("user", response.token, { path: "/" });
        login(response.token);
        navigate("/");
      } else {
        // Handle login error
        alert(response.error);
      }
    } catch (error) {
      console.log("Error during login:", error);
    }
  };

  return (
    <div className=" w-full flex justify-center items-center h-[80vh] p-5">
      <div className=" w-full md:w-[500px]  px-10 py-8 shadow-lg rounded-xl bg-white ">
        <h5 className="mb-10 text-2xl font-semibold tracking-tight text-title text-center">
          LOGIN
        </h5>

        <div>
          <div className="mb-6 text-left">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-default dark:text-default"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="name@email.com"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="mb-2 text-left">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-default dark:text-default"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>

          <Link to={"/forgot/password"} className=" text-blue-600">
            {" "}
            Forgot your password ?
          </Link>

          {/* <div className="flex items-start mb-6">
          <div className="flex items-center h-5">
            <input
              id="remember"
              type="checkbox"
              value=""
              className="w-4 h-4 focus:accent-button-blue border-default rounded-xl"
            />
          </div>
          <label
            htmlFor="remember"
            className="ml-2 text-sm font-medium text-default dark:text-default"
          >
            Remember me
          </label>
        </div> */}
          <div className=" flex justify-between mt-6">
            <button
              onClick={() => handleLogin()}
              className="w-fit mb-3 py-2 px-5 rounded-lg font-normal text-white bg-header"
            >
              LOGIN
            </button>
            <div className="w-fit mb-3 py-2 px-5 rounded-md font-normal text-green-700 ">
              <Link to={"/signup"}> Have not account?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
