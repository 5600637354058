export function getPostDateTime(dateTime) {
  const d = new Date(dateTime);

  const year = d.getUTCFullYear();
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const day = String(d.getUTCDate()).padStart(2, "0");

  const date = `${year}-${month}-${day}`;

  const hours = String(d.getUTCHours()).padStart(2, "0");
  const minutes = String(d.getUTCMinutes()).padStart(2, "0");
  const seconds = String(d.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(d.getUTCMilliseconds()).padStart(3, "0");

  const time = `${hours}:${minutes}:${seconds}.${milliseconds}`;

  return { date, time };
}
