import React from 'react'
import ProductItem from '../../component/Item/ProductItem'

function PostProduct() {
  return (
    <div className='w-full mt-5 '>
      <div className='flex flex-row justify-between bg-white px-5 py-3 lg:p-5'>
        <p className='text-[20px] font-[600]'> Products</p>
        <p className='px-5 py-2 bg-header text-white rounded-[25px] cursor-pointer'> Post Product</p>
      </div>
      <div className='mt-5 grid grid-cols-2 lg:grid-cols-5 gap-5 px-2 lg:px-0'>
      <ProductItem/>
      <ProductItem/>
      <ProductItem/>
      <ProductItem/>
      <ProductItem/>
      <ProductItem/>
      </div>
    </div>
  )
}

export default PostProduct