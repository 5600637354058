import React, { useState , useEffect} from "react";

// import { url } from "../../config";
import image from "../../image/user.jpg";
import { User, Setting } from "./../icons/index";
import { useMyAcount } from "../../utils/myAcountContext";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from "../../ApiRequest";
import { useCookies } from "react-cookie";

function Profile() {
  const {personalData, setPersonalData} = useMyAcount();

  const [toggle, setToggle] = useState(false);
  const [cookie] = useCookies(["user"]);
  const token = cookie.user;
  const toggleProfile = () => {
    setToggle((toggle) => !toggle);
  };

  const navigate = useNavigate();
  const fetchUser = async () => {
    try {
      const response = await ApiRequest("GET", "api/v1/me", null, token);
      setPersonalData({
        profile: response.user.profile,
        name: response.user.name
      })
      if (response.error) {
        localStorage.clear("");
        navigate("/");
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Link to={"/myaccount"} > */}
      <div className=" relative">
        <div
          className="w-[50px] h-[50px] border rounded-full overflow-hidden cursor-pointer"
          onClick={() => toggleProfile()}
        >
          <img
            src={personalData === "" ? image : personalData.profile }
            alt=""
            className=" w-full h-full object-cover "
          />
        </div>
        {toggle ? (
          <div className="w-60 p-5 absolute top-[110%] right-[20%] z-10 bg-white text-black rounded-lg border-1 shadow-lg">
            <div className="text-center mb-5 w-full flex flex-col justify-center items-center">
              <div className=" w-24 h-24 mb-2.5 rounded-full overflow-hidden border-[gray] border-[1px] align-middle">
                <img
                  className="m-auto w-full h-full rounded-full object-cover "
                  src={
                    personalData === ""
                      ? image
                      : personalData.profile
                  }
                  alt=""
                />
              </div>

              <h6 className="font-semibold">{personalData.name} </h6>
            </div>
            <hr />
            <div className="mt-2 mb-2">
            
                <div
                  onClick={() => toggleProfile()}
                  className="flex items-center px-4 py-2 text-sm text-default hover:bg-button-blue hover:text-white rounded-lg transition-all duration-150 ease-out"
                >
                  <User props="text-lg" />
                  <Link to={`/profile/${personalData.name}`}>
                    <span className="ml-2.5 font-semibold">View Profile</span>
                  </Link>
                </div>
            
              <Link to="/setting">
                <div
                  onClick={() => toggleProfile()}
                  className="flex items-center px-4 py-2 text-sm text-default hover:bg-button-blue hover:text-white rounded-lg transition-all duration-150 ease-out"
                >
                  <Setting props="text-lg" />
                  <p className="ml-2.5 font-semibold">Setting</p>
                </div>
              </Link>
            </div>
            <hr />
            <div className="mt-2">
              <button
                // onClick={logout}
                className="w-full text-center px-4 py-2 bg-button-blue text-white font-semibold hover:bg-[red] hover:text-white rounded-lg "
              >
                Sign out
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* </Link> */}
    </>
  );
}

export default Profile;
