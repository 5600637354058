import axios from "axios";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductItem from "../component/Item/ProductItem";
import Loading from "../component/loading/Loading";

function Pets() {
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // fetch product
  const fetchProduct = () => {
    return axios
      .get(
        `https://seyhashop.onrender.com/products?categoryId=9&_page=${page}&_limit=10`
      )
      .then((result) => {
        setProduct([...product, ...result.data]);
        setPage(page + 1);
        setHasMore(() => (result.data.length === 0 ? false : true));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // delay loading
  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      fetchProduct();
    }, 1000);
  };
  return (
    <div className="w-full flex flex-row gap-5 2xl:px-0 px-5 py-5 m-auto  ">
       <div className="w-[23%] h-fit flex flex-col gap-5">
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Categories</span>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Types</span>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Locations</span>
          {/* <Text value={types} /> */}
        </div>
      </div>
      <div className={"w-[77%] "}>
        <InfiniteScroll
          dataLength={product.length}
          next={handleLoadMore}
          hasMore={hasMore}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {product.map((item) => (
              <ProductItem key={item.id} {...item} />
            ))}
          </div>
        </InfiniteScroll>

        {isLoading && <Loading />}
      </div>
    </div>
  );
}

export default Pets;
