import { getPostDateTime } from "./getPostDateTime";

export function getPostDate(dates) {
  const timeZone = getPostDateTime(dates);
  const time = timeZone.time;
  const date = timeZone.date;
  const previousTimeStamp = new Date(date + " " + time).getTime();
  const lastTimeStamp = new Date().getTime();

  const difference = lastTimeStamp - previousTimeStamp;
  const minutes = difference / (1000 * 60);

  if (minutes < 1) {
    return "Just now";
  } else {
    const minutesFinal = Math.floor(minutes);
    // const secondsFinal = difference - (minutesFinal * 60 * 1000);
    const hours = minutesFinal / 60;

    if (hours < 1) {
      return `${minutesFinal} minutes ago`;
    } else {
      const hoursFinal = Math.floor(hours);
      // const minsSuperFinal = minutesFinal - (hoursFinal * 60);

      if (hoursFinal < 24) {
        return `${hoursFinal} hours ago`;
      } else if (hoursFinal < 48) {
        return `Yesterday ${time}`;
      } else {
        const d = new Date(date);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = d.toLocaleDateString("en-US", options);
        return `${formattedDate}`;
      }
    }
  }
}
