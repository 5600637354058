import { createContext, useContext, useState } from "react";

const MyAcountContext = createContext("");
export const useMyAcount = () => useContext(MyAcountContext);
export const MyAccountProvider = ({ children }) => {
  const [personalData, setPersonalData] = useState({});
  return (
    <MyAcountContext.Provider value={{ personalData, setPersonalData }}>
      {children}
    </MyAcountContext.Provider>
  );
};
