import React, { useState } from "react";
import { ApiRequest } from "../../ApiRequest";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigator = useNavigate();
  const handleForgot = async () => {
    try {
      const response = await ApiRequest("POST", "api/v1/password/forgot", {
        email,
      });
      console.log(response)

      if (response.success === true) {
        localStorage.setItem("resetToken", response.resetToken);
        navigator(`/resetpassword/${response.resetToken}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className=" w-full h-full p-5">
      <div className=" w-full md:w-[500px]  m-auto md:mt-[21%] md:transform md:-translate-y-[21%] text-center  px-10 py-8 shadow-lg rounded-xl bg-white">
        <p className="mb-5 text-default text-left">
          Forgot your password? No problem. Just let us know your email address
          and we will email you a password reset link that will allow you to
          choose a new one.
        </p>

        <div>
          <div className="mb-6 text-left">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-default dark:text-default"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-button-blue"
              placeholder="name@email.com"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className=" flex justify-end">
            <button
              onClick={() => handleForgot()}
              className="bg-button-blue w-fit mb-3 py-2 px-5 rounded-lg font-normal text-white bg-blue-800"
            >
              Email Password Reset Link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
