import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequest } from "../../ApiRequest";

function SignUp() {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    phone: "",
  });
  const name = useRef();
  const userName = useRef();
  const email = useRef();
  const password = useRef();
  const phone = useRef();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handelRegister = async () => {
    try {
        if (formData.name === "") {
        alert("Please input name");
        name.current.focus();
        return;
      } else if (formData.username === "") {
        alert("Please input username");
        userName.current.focus();
        return;
      } else if (formData.email === "") {
        alert(" Please input Email ");
        email.current.focus();
        return;
      } else if (formData.password === "") {
        alert("Please input password");
        password.current.focus();
        return;
      } else if (formData.phone === "") {
        alert("Please input phone ");
        phone.current.focus();
        return;
      }

      const response = await ApiRequest("POST", "api/v1/register", formData);
      if (response.success) {
        navigate("/signin");
      }
      if (response.error) {
        alert(response.error);
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className=" w-full flex justify-center items-center h-full mt-10 p-5">
        <div className=" w-full md:w-[500px]  text-center px-10 py-8 shadow-lg rounded-xl bg-white">
          <h5 className="mb-10 text-2xl font-semibold tracking-tight text-title">
            SignUp
          </h5>

          <div>
            <div className="mb-6 text-left">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-default dark:text-default"
              >
                Fullname
              </label>
              <input
                id="name"
                type="text"
                name="name"
                className={
                  "bg-div-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-800"
                }
                placeholder="James Bond"
                required
                ref={name}
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6 text-left">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-default dark:text-default"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-800"
                placeholder="Username"
                required
                ref={userName}
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>

            <div className="mb-6 text-left">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-default dark:text-default"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                placeholder="name@email.com"
                required
                ref={email}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="mb-6 text-left">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-default dark:text-default"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                required
                ref={password}
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>

            <div className="mb-6 text-left">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-default dark:text-default"
              >
                Phnone
              </label>
              <input
                type="tel"
                id="phone"
                placeholder="Phone number"
                className="bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                required
                ref={phone}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            {/* <div className="flex items-start mb-6">
          <div className="flex items-center h-5">
            <input
              id="remember"
              type="checkbox"
              value=""
              className="w-4 h-4 focus:accent-button-blue border-default rounded-xl"
            />
          </div>
          <label
            htmlFor="remember"
            className="ml-2 text-sm font-medium text-default dark:text-default"
          >
            Remember me
          </label>
        </div> */}
            <div className=" flex justify-between">
              <button
                onClick={() => handelRegister()}
                className="w-fit mb-3 py-2 px-5 rounded-lg font-normal text-white bg-blue-800 bg-button-blue"
              >
                Sign Up
              </button>
              <div className="w-fit mb-3 py-2 px-5 rounded-md font-normal text-green-700 ">
                <Link to={"/signin"}>Have already acount</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
