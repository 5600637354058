import React, { useEffect, useState } from "react";
import Item from "./Item";
import { useAuth } from "../../utils/auth";
import image from "../../image/logo192.png";
import { ApiRequest } from "../../ApiRequest";
const ItemsList = ({ homepage }) => {
  const { setMenuBar, menubar } = useAuth();
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await ApiRequest("GET", "api/v1/categories");
      console.log(response);
      setCategories(response.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      {menubar ? (
        <div
          className=" fixed w-full h-full left-0 top-0 bg-[#00000050] transition-all duration-[1s] ease-in z-50 "
          onClick={() => {
            setMenuBar(false);
          }}
        ></div>
      ) : (
        ""
      )}

      {categories.length !== 0 ? (
        <div
          className={
            menubar
              ? "w-[50%] md:w-[20%] h-[100vh] fixed   p-4 bg-white z-50 top-0 left-0 transition-all duration-[0.3s] ease-in"
              : "w-[50%] md:w-[20%] h-[100vh] fixed   p-4 bg-white z-50 top-0 left-[-100%] transition-all duration-[0.3s] ease-out"
          }
        >
          <div
            className={" absolute w-full bg-blue-900  left-0 top-0 py-3 px-5"}
          >
            <img src={image} alt="" width={"50"} />
          </div>

          <ul className={" nav flex flex-col overflow-hidden mt-14 "}>
            {categories.map((item) => (
              <li className="nav" key={item.id}>
                <Item {...item} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="w-[23%]"> </div>
      )}
    </>
  );
};

export default ItemsList;
