import React from 'react'
import {FaTelegramPlane} from'react-icons/fa'
function Telegram({props}) {
  return (
    <>
        <FaTelegramPlane className={props}/>
    </>
  )
}

export default Telegram