import React from "react";

import { Link, useParams } from "react-router-dom";
// import FormApply from "../../pages/jobs/FormApply";
// import Modal from "../modal/Modal";
import { Calendar, Position, Map } from "../../component/icons/index";
import Calander from "../icons/Calander";
import { CgCheck } from "react-icons/cg";
import { getPostDate } from "../../utils/getPostDate";

function ItemJob({
  _id,
  title,
  thumbnail,
  user,
  best_way,
  type,
  closed,
  salary,
  location,
  createdAt,
}) {
  //   const [formApply, setFormApply] = useState(false);
const {idUser} = useParams();

  return (
    <>
      <div className="w-full h-fit px-[20px] lg:px-[30px] py-[15px] lg:py-[20px] rounded-[10px] bg-white flex flex-row gap-[20px] relative ">
        <Link to={`/job/detail/${_id}`}>
          <img
            src={thumbnail}
            alt=""
            className="lg:w-[85px] lg:h-[85px] h-[60px] w-[60px] rounded-full object-cover bg-default"
          />
        </Link>

        <div className="flex flex-col gap-[20px] w-[75%]">
          <Link to={`/profile/${idUser}/jobupdate/${_id}`} className=" absolute right-5 ">
            Edit
          </Link>

          <div className="flex flex-col ">
            <div className="text-sub-title text-[16px] font-[500] flex flex-row justify-between items-center ">
              <span className="text-sub-title text-[16px] font-[500] ">
                <Link to={`/job/detail/`}>{title}</Link>
              </span>
              {/* <span
                // onClick={() => {
                //   setFormApply(true);
                // }}
                className=" absolute right-5 bg-[#EEEE] border-[1px] border-button-blue px-[12px] py-[8px] rounded-[25px] text-button-blue text-[14px] font-[400] cursor-pointer"
              >
                Apply Now
              </span> */}
            </div>
            <div className="flex flex-row gap-[5px] items-center mb-2">
              <span className="text-default text-[14px] font-[500] uppercase">
                {user}
              </span>
              <div className=" text-white  bg-button-blue w-[14.53px] h-[14.53px] rounded-full flex justify-center items-center">
                <CgCheck props={"text-[10px]"} />
              </div>
            </div>
            <div className="flex flex-row gap-[5px] items-center ">
              {salary !== "" && (
                <span
                  className={
                    "bg-[#EEEE] rounded-[5px] px-[10px] py-[5px] text-default text-[14px] font-[400] capitalize"
                  }
                >
                  {salary}
                </span>
              )}
            </div>
          </div>

          <div className=" grid grid-cols-2   xl:grid-cols-4 gap-3 xl:gap-[30px] ">
            <div className="flex flex-row gap-[5px] items-center justify-start">
              <span>
                <Position />
              </span>
              <span className="text-default text-[12px] font-[400] capitalize">
                {type}
              </span>
            </div>
            <div className="flex flex-row gap-[5px] items-center justify-start">
              <span>
                <Calendar />
              </span>
              <span className="text-default text-[12px] font-[400] capitalize">
                {/* {createdAt} */}
                {getPostDate(createdAt)}
              </span>
            </div>
            <div className="flex flex-row gap-[5px] items-center  justify-start">
              <span>
                <Calander />
              </span>
              <span className="text-default text-[12px] font-[400] capitalize">
                {closed}
              </span>
            </div>
            <div className="flex flex-row gap-[5px] items-center  justify-start">
              <span>
                <Map />
              </span>
              <span className="text-default text-[12px] font-[400] capitalize">
                {location}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* {console.log(formApply)} */}
    </>
  );
}

export default ItemJob;
