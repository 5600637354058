import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Camera } from "../../component/icons";

// import { Facebook } from "../../component/icons/index";
// import RatingStar from '../../services/RatingStar';

const ProfileMenu = [
  {
    id: 1,
    name: "Product",
    slug: "",
  },
  {
    id: 2,
    name: "Service",
    slug: "service",
  },
  {
    id: 3,
    name: "Job",
    slug: "job",
  }
];

const MyProfileLayout = () => {
  return (
    <div className="w-full ">
      <div className="flex flex-col">
        <div className="animate-fade-up w-full h-[450px]  bg-white">
          <div className="relative rounded-t-xl w-full h-[70%] flex items-end border-b border-[#80808056]">
            <img
              className="absolute w-full h-full object-cover rounded-t-xl"
              src={""}
              alt=""
            />

            <div className=" cursor-pointer absolute right-5 bottom-5 bg-white px-3 py-2 rounded-lg flex gap-3 items-center border-[#8080802b] border-[0.5px]">
              <Camera />
              <span className="text-[14px] font-[500]"> Add Cover Photo</span>
            </div>
            <div className="w-[120px] h-[120px] border-[1.2px] border-[#80808056]  rounded-full flex justify-center items-center absolute bottom-[-40px] left-5 lg:left-10 ">
              <img
                className=" w-full h-full object-cover rounded-full "
                src={""}
                alt=""
              />

              <div className=" cursor-pointer bg-white w-[30px] h-[30px] rounded-full flex justify-center items-center absolute right-[-10px] bottom-5 border-[#8080802b] border-[0.5px]">
                <Camera />
              </div>
            </div>
          </div>
          <div className="w-full md:pl-[150px] p-10 py-10 md:py-5  h-[30%]   flex justify-end ">
            <div className="w-full ">
              <p className="text-[22px] md:text-[28px] font-[700] lg:mb-2">
                {" "}
                Oeurn Seyha{" "}
              </p>
              <div className=" text-[gray]">
                <p className="text-[14px] md:text-[16px]"> @username</p>
                <p className="text-[14px] md:text-[16px]">
                  {" "}
                  Member since 07-Oct-2022
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-white  flex flex-row gap-5 border-t-[1px] border-[#80808056]">
          {ProfileMenu.map((item, id) => (
            <NavLink
              key={id}
              end
              to={`${item.slug}`}
              className={({ isActive }) => {
                return (
                  "font-[500] nav-link  flex items-center px-5 p-2 hover:text-white hover:bg-header transition-all duration-[10ms] ease-out " +
                  (!isActive
                    ? "text-sub-title"
                    : "text-header border-b-[2px] border-header ")
                );
              }}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
        <main role="main" className="flex flex-1 flex-col gap-5">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MyProfileLayout;

// <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-transparent to-[#111] rounded-t-xl"></div>
// <div className="w-full p-5 z-10 flex justify-between items-end">
//   <div className="">
//     <h3 className="tracking-tight font-medium leading-7 text-white text-xl mb-2.5">
//       {/* {page.title} */}
//     </h3>
//     <div className="flex space-x-2.5">
//       <div className="w-fit flex space-x-2 bg-background-default rounded-2xl py-1 px-2">
//         <Link className="p-2 rounded-full bg-white">
//           <Facebook props="text-facebook text-sm text-blue-600" />
//         </Link>

//         {/* {page.socialLink && page.socialLink.youtube && (
//           <a href={page.socialLink.youtube}>
//             <YouTube props="text-youtube text-sm" />
//           </a>
//         )}
//         {page.socialLink && page.socialLink.instagram && (
//           <a href={page.socialLink.instagram}>
//             <Instagram props="text-instagram text-sm" />
//           </a>
//         )}
//         {page.socialLink && page.socialLink.twitter && (
//           <a href={page.socialLink.twitter}>
//             <Twitter props="text-twitter text-sm" />
//           </a>
//         )}
//         {page.socialLink && page.socialLink.telegram && (
//           <a href={page.socialLink.telegram}>
//             <Telegram props="text-telegram text-sm" />
//           </a>
//         )}
//         {page.socialLink && page.socialLink.website && (
//           <a href={page.socialLink.website}>
//             <Earth props="text-button-default text-sm" />
//           </a>
//         )} */}
//       </div>
//       {/* <RatingStar averageRating={averageRating} /> */}
//     </div>
//   </div>
// </div>

// <div className="p-4 bg-white rounded-xl w-full">
// <ul className="flex flex-row md:flex-col gap-[5px] w-full">
//   {ProfileMenu.map((option, index) => (
//     <NavLink
//       key={index}
//       end
//       to={`${option.slug}`}
//       className={({ isActive }) => {
//         return (
//           "nav-link h-[39px] flex items-center p-2 hover:text-white hover:bg-blue-800 rounded-[5px] transition-all duration-[10ms] ease-out " +
//           (!isActive
//             ? "text-sub-title"
//             : "text-[#FFFFFF] bg-blue-800")
//         );
//       }}
//     >
//       <li key={option.name} className=" px-2 font-normal	">
//         {option.name}
//       </li>
//     </NavLink>
//   ))}
// </ul>
// </div>
