import React from "react";
import logo from "../image/logo192.png";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth";
import Profile from "../component/profile/Profile";
import { Bar, Search } from "../component/icons";
function Header() {
  const { user, setMenuBar } = useAuth();
  const location = useLocation();
  const excludedRoutes = ["/"];
  const showHamburgerIcon = !excludedRoutes.includes(location.pathname);
  return (
    <div className=" bg-header sticky top-0 z-50 px-3 2xl:px-0">
      <div className="w-full xl:w-[1300px] m-auto h-[70px] lg:px-0 flex flex-row justify-between items-center">
        <div className="md:w-[25%] w-[50%] flex justify-start items-center">
          <span
            onClick={() => {
              setMenuBar(true);
            }}
          >
            {showHamburgerIcon ? (
              <Bar props={"text-white text-[18px] mr-3"} />
            ) : (
              ""
            )}
          </span>

          <Link to="/">
            <img src={logo} alt="" className="lg:w-[50px] w-[50px]" />
          </Link>
        </div>
        <div className="relative w-[35%] hidden lg:flex">
          <input
            type="text"
            className="  w-full border-[1.8px] border-white rounded-3xl transition-colors bg-transparent text-white focus:outline-none focus:border-[1.6px] focus:text-white h-[40px] px-3 placeholder:text-white"
            placeholder="Search..."
            // value={input}
            // onChange={(e) => handleChange(e.target.value)}
          />
          <button className="block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 focus:outline-none hover:text-gray-900 transition-colors">
            <Search props="text-white text-sm" />
          </button>
        </div>
        <div className="md:w-[25%] w-[50%] flex justify-end text-white uppercase font-[500] text-[12px] md:text-[16px]">
          {user !== null && user !== undefined ? (
            <Profile />
          ) : (
            <div className="flex gap-3">
              <Link to={"/signin"}> signin</Link> |{" "}
              <Link to={"signup"}>signup </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
