import React, { useEffect, useState } from "react";
import ItemJob from "../../component/item-job/ItemJob";
import { ApiRequest } from "../../ApiRequest";
import { Link } from "react-router-dom";

function PagePostJob() {

  const [jobData, setJobData] = useState([]);

  const fetchJobData = async () => {
    try {
      const jobData = await ApiRequest("GET", "api/v1/jobs");
      setJobData(jobData.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);




  return (
    <div className="w-full mt-5 ">
      <div className="flex flex-row justify-between bg-white px-5 py-3 lg:p-5">
        <p className="text-[20px] font-[600]"> Jobs </p>
        <Link
          className="px-5 py-2 bg-header text-white rounded-[25px] cursor-pointer"
          to="/profile/oeurnseyhas/postjob"
        >
          Post Job
        </Link>
      </div>

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-5 px-2 lg:px-0">
        {jobData.map((item) => (
          <ItemJob key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
}

export default PagePostJob;
