import React from 'react'
import { FaFacebookF } from 'react-icons/fa'
function FaceBook({ props }) {
    return (
        <>
            <FaFacebookF className={props} />
        </>
    )
}

export default FaceBook