import React from 'react';
import {FaShare} from"react-icons/fa" 
const Share = ({props}) => {
    return (
        <>
            <FaShare className={props}/>
        </>
    );
}

export default Share;
