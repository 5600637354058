import React from 'react'
import {FaCalendarTimes} from'react-icons/fa'
function Calander({size}) {
  return (
    <>
    <FaCalendarTimes className={size}/>
    </>
  )
}

export default Calander