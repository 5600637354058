import React from 'react'
import {FaLinkedinIn} from'react-icons/fa'
function LinkIn({props}) {
  return (
   <>
    <FaLinkedinIn className={props}/>
   </>
  )
}

export default LinkIn