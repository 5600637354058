import React, { useEffect, useState } from "react";
import { ApiRequest } from "../ApiRequest";
import ProductItem from "../component/Item/ProductItem";
import ItemsList from "../component/menu/ItemsList";

function Services() {
  const [services, setServices] = useState([]);
  const fetchServices = async () => {
    try {
      const response = await ApiRequest("GET", "api/v1/services");
      console.log(response);
      setServices(response.services);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);
  return (
    <div className="w-full flex gap-5 py-5">
      <div className="w-[23%] h-fit flex flex-col gap-5">
        <ItemsList/>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Categories</span>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Types</span>
          {/* <Text value={types} /> */}
        </div>
        <div className="pt-[20px] pb-[10px] px-[20px] bg-white rounded-[10px] flex flex-col gap-[10px] border-[1px] border-solid border-soft-border">
          <span className=" text-[16px] font-[500] uppercase ">Locations</span>
          {/* <Text value={types} /> */}
        </div>
      </div>
      <div className={"w-[77%]  bg-blue-200 "}>
        {/* <InfiniteScroll
        dataLength={product.length}
        next={handleLoadMore}
        hasMore={hasMore}
      > */}

        <div className="grid grid-cols-1 gap-5 w-full">
          {/* {jobData.map((item) => (
          <ItemJob key={item.id} {...item} />
        ))} */}

          {services.map((item, id) => (
            <ProductItem key={id} {...item} />
          ))}
        </div>
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
}

export default Services;
