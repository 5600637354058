import React from 'react'
import { FaBars } from 'react-icons/fa'


const Bar = ({props}) => {
    return (
        <FaBars className={props} />
    )
}

export default Bar