import React from "react";
// import { Link, NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Calendar,
  CheckCircleFill,
  Eye,
 
  Map,
 
  Share,
  Squares,
} from "../../component/icons/index";

import DisclimerProduct from "../../component/disclimer-product/DisclimerProduct";
import { FacebookShareButton } from "react-share";
const currentUrl = typeof window !== "undefined" ? window.location.href : "";
const guidlineList = [
  {
    id: 1,
    tittle: "Ask question to be clear on vehicle details",
  },
  {
    id: 2,
    tittle: "be aware of time & when going for vehicle test-drive",
  },
  {
    id: 3,
    tittle: "don't make a payment without verifying the credentails ",
  },
];

const Detail = () => {

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`${currentUrl}`} />
        <meta
          property="og:title"
          content="SEYHA-SHOP Buy and Sell Everything In Cambodia"
        />
        <meta
          property="og:description"
          content="Sell your 2nd hand items on seyhashop.pages.dev, the Cambodia #1 buy and sell website. Post free online classified ads of your property, real estate, home, land, car, motorcycle, mobile phone, computer, electronic, furniture, job, service and more."
        />
        <meta
          property="og:keywords"
          content="buy and sell, online shopping, shopping, shopping in Cambodia, real estate, classified, listing, post free ads, classified ads, property, home, land, car, mobile phone, computer, electronic, furniture, job, service, motorcycle, product price"
        />
        <meta
          property="og:image"
          content="https://seyhashop.pages.dev/01.webp"
        />
      </Helmet>
      <div className="w-full py-5 px-0 xl:px-2">
        <div className="flex flex-col lg:flex-row  gap-5">
          <aside className=" w-full lg:w-[77%]">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex flex-col gap-2.5 p-5 bg-white rounded-lg">
                <div className="flex justify-between">
                  <h3 className="text-xl font-[700]">{" jhjhlkjjgyl"}</h3>
                </div>
                <div className="flex gap-5 mb-2">
                  <div className="flex items-center gap-2">
                    <Squares props="text-default text-[14px]" />
                    <p className=" capitalize text-[14px]">category</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar props="text-default text-[14px]" />
                    <p className="text-[14px]">July 01,2023</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Map props="text-default text-[14px]" />
                    <p className="text-[14px]"> location </p>
                  </div>
                  <div className="flex items-center justify-center gap-2">
                    <Eye props="text-default text-[14px]" />
                    <p className="">view</p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className=" bg-green  flex flex-row items-center justify-end gap-2 rounded-[25px] px-3 py-1.5">
                    <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                      <img
                        src="https://www.khmer24.com/v1.0.4/template/img/khmer24.gif"
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <span className="text-white text-[14px]">
                      {" "}
                      Name Account{" "}
                    </span>
                  </div>
                  <FacebookShareButton url={`${window.location.href}`}>
                    <span className="text-default">
                      {" "}
                      <Share props={"text-[16px]"} />{" "}
                    </span>
                  </FacebookShareButton>
                </div>
              </div>

              {/************* SLIDER *************/}

              <div className="p-5 bg-white rounded-lg w-full ">
                <p className="text-[16px] font-[600]"> Description </p>
              </div>

              <div className="w-full bg-white p-5 flex flex-col gap-2.5 rounded-lg">
                <h3 className="font-[600] text-[#0C9000]">Buyer Guidline</h3>
                <div className="w-full border-b bg-black" />
                {guidlineList.map((item, index) => (
                  <div key={index} className=" flex items-center gap-2.5">
                    <CheckCircleFill size="text-[#2550A6]" />
                    <p className="">{item.tittle}</p>
                  </div>
                ))}
              </div>

              <div className="bg-white rounded-lg w-full p-5">
                <DisclimerProduct />;
              </div>

              <div className="">
                <h3 className="mb-5 font-[600]">Similar Services</h3>
                <div className="grid grid-cols-4 gap-5 rounded-lg ">
                  {/* {products.map((item, index) => {
                    return (
                      <ProductItem key={index} {...item} {...item.details} />
                    );
                  })} */}
                </div>
              </div>
            </div>
          </aside>

          <main className=" w-full lg:w-[23%] flex flex-col gap-5">
            <div className="w-full p-5 bg-white rounded-lg">
              <div className="mb-5 flex justify-between items-center">
                <h3 className=" text-title font-[600] text-xl">Page review</h3>
                <p className="text-button-blue"> Explore more </p>
              </div>
            </div>

            <div className="w-full flex flex-col gap-5 p-5 bg-white rounded-lg">
              <h3 className="text-title font-[600]">Other Pags</h3>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Detail;
