import { createContext, useContext, useState } from "react";
const AuthContext = createContext("");
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {


  const [user, setUser] = useState(localStorage.getItem("token"));
  const [menubar, setMenuBar] = useState(false);
  const login = (user) => {
    localStorage.setItem("token", user)
    setUser(user);
  };

  const logout = async () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, menubar, setMenuBar }}>
      {children}
    </AuthContext.Provider>
  );
};
