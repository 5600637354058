import React, { useState } from "react";
import { ApiRequest } from "../../ApiRequest";

function Password() {
  const [formInput, setFormInput] = useState({
    currentPassword: "",
    newPassword: "",
    comfirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdatePassword = async () => {
    try {
      const response = await ApiRequest(
        "PUT",
        "api/v1/update/password",
        formInput,
        localStorage.getItem("token")
      );
      if (response.error) {
        alert(response.error);
      }
      if (response.success) {
        setFormInput({
          currentPassword: "",
          newPassword: "",
          comfirmPassword: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="bg-white rounded-[10px] shadow-lg p-5">
        <h1 className="text-xl font-bold">Password & Security</h1>
        <div>
          {/* Hidden username field for accessibility */}

          <div className="flex flex-col gap-[10px] text-sm mt-2 pt-[10px]">
            <div className="pt-2 flex flex-col md:flex-row md:items-center gap-3">
              <label className="w-[150px]">Current Password</label>
              <input
                type="password"
                name="currentPassword"
                value={formInput.currentPassword}
                // autoComplete="current-password"
                onChange={handleChange}
                className="h-[41px] bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-button-blue"
                placeholder="Current Password"
              />
            </div>

            <div className="pt-2 flex flex-col md:flex-row md:items-center gap-3">
              <label className="w-[150px]">New Password</label>
              <input
                type="password"
                value={formInput.newPassword}
                name="newPassword"
                onChange={handleChange}
                className="h-[41px] bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-button-blue"
                placeholder="New Password"
              />
            </div>

            <div className="pt-2 flex flex-col md:flex-row md:items-center gap-3">
              <label className="w-[150px]">Confirm Password</label>
              <input
                type="password"
                value={formInput.comfirmPassword}
                name="comfirmPassword"
                onChange={handleChange}
                className="h-[41px] bg-button-blue bg-opacity-5 appearance-none border-2 border-button-blue border-opacity-5 rounded-lg w-full py-2 px-4 text-default leading-tight focus:outline-none focus:bg-white focus:border-button-blue"
                placeholder="Confirm Password"
              />
            </div>

            <div className="col-span-6 text-right">
              <div className="pr-3 mt-2">
                <button
                  onClick={() => handleUpdatePassword()}
                  className="text-[#fff] py-2 px-6 rounded-[5px] bg-[#0055FF] font-normal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
