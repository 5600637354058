import React, { useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { ApiRequest } from "../../ApiRequest";
import { useParams } from "react-router-dom";

function JobUpdate() {
  const editorRequirement = useRef(null);
  const editorDuties = useRef(null);
  const [locations, setLocation] = useState([]);
  const [types, setType] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [data, setData] = useState({});
  const { idjob } = useParams();
  // const [_idSubCategory, setIdSubCategory] = useState();
  // const [ idLocation, setIdLocation] = useState();
  // const [ idType, setIdType] = useState();

  const getOneJob = async () => {
    try {
      const response = await ApiRequest("GET", `api/v1/getone/job/${idjob}`);
      setData(response.jobs[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const findIdSubcategory = async () => {
    try {
      const response = await ApiRequest(
        "GET",
        `api/v1/subcategories?name=${"Web developer"}`
      );
      // setIdSubCategory(response.subcategories._id);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handelPostJob = async () => {
    try {
      const formData = new FormData();
      formData.append("thumbnail", data.thumbnail);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("salary", data.salary);
      formData.append("subcategory", data.subcategory);
      formData.append("type", data.type);
      formData.append("location", data.location);
      formData.append("closed", data.closed);
      formData.append("requirement", editorRequirement.current.value);
      formData.append("duties", editorDuties.current.value);
      const response = await ApiRequest(
        "POST",
        "api/v1/add/job",
        formData,
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NTA1ZDRiZDY4NzllYzllNzVlYWVlMSIsImlhdCI6MTcxMDIwNzkwMywiZXhwIjoxNzEwODEyNzAzfQ.3j7H2CY4CfRr15zPgccdyPMs56nAdoK_667K1pr2spQ"
      );

      if (response.success) {
        alert("Job Posted successfully");
        setData({
          title: "",
          description: "",
          salary: "",
          subcategory: "",
          type: "",
          location: "",
          closed: "",
          thumbnail: null,
          requirement: "",
          duties: "",
        });
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // if it 's afile input , store the selected file in the state

    if (files) {
      setData((preData) => ({
        ...preData,
        [name]: files[0],
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchType = async () => {
    try {
      const response = await ApiRequest("GET", "api/v1/types?category=Jobs");
      setType(response.types);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSubcategory = async () => {
    try {
      const response = await ApiRequest(
        "GET",
        "api/v1/subcategories?category=jobs"
      );

      console.log("hello" + response);
      setSubcategories(response.subcategories);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLocation = async () => {
    try {
      const response = await ApiRequest("GET", "api/v1/locations");
      setLocation(response.locations);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOneJob();
    fetchLocation();
    fetchType();
    fetchSubcategory();
    findIdSubcategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data.subcategory);
  return (
    <div className=" mt-5 bg-white p-5 rounded-lg">
      <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full">
        <label className="w-full">
          Title
          <div className="mt-2">
            <input
              type="text"
              name="title"
              value={data.title}
              onChange={handleChange}
              placeholder="title"
              className=" w-full block  rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
            />
          </div>
        </label>
        <label className="w-full">
          Description
          <div className="mt-2">
            <input
              onChange={handleChange}
              type="text"
              name="description"
              value={data.description}
              placeholder="description"
              className="  w-full block  rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
            />
          </div>
        </label>
        <label className="w-full">
          Salary
          <div className="mt-2">
            <input
              onChange={handleChange}
              type="text"
              name="salary"
              value={data.salary}
              placeholder="salary"
              className="  w-full block  rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
            />
          </div>
        </label>
        <label className="w-full">
          Category
          <div className="mt-2">
            <select
              onChange={handleChange}
              value={"65cedcae57712314507e50e7"} // Set value dynamically based on data.subcategory
              id="subcategory"
              name="subcategory"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value={""}>{"Category"}</option>

              {subcategories.map((item, id) => (
                <option value={item._id} key={id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label className="w-full">
          Type
          <div className="mt-2">
            <select
              onChange={handleChange}
              value={data.type}
              id="country"
              name="type"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value={""}>{"Type"}</option>

              {types.map((item, id) => (
                <option value={item._id} key={id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label className="w-full">
          Location
          <div className="mt-2">
            <select
              onChange={handleChange}
              value={data.location}
              name="location"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
              <option value={""}>Location</option>

              {locations.map((item, id) => (
                <option value={item._id} key={id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </label>
        <label className="w-full">
          Thumbnail
          <div className="mt-2">
            <input
              onChange={handleChange}
              name="thumbnail"
              type="file"
              className="w-full block  rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            />
          </div>
        </label>
        <label>
          Closed
          <div className="mt-2">
            <input
              onChange={handleChange}
              value={data.closed}
              type="date"
              name="closed"
              className=" border-[1px] border-[#0000ff69] px-5 py-1 rounded-lg "
            />
          </div>
        </label>
      </div>
      <div className="flex flex-col gap-3">
        <h1 className="text-[20px] font-[500]"> Job Requirement </h1>
        <div>
          <JoditEditor ref={editorRequirement} value={data.requirement} />
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <h1 className="text-[20px] font-[500]"> Job Duties </h1>
        <div>
          <JoditEditor ref={editorDuties} value={data.duties} />
        </div>
      </div>
      <button
        className=" text-white bg-button-blue px-3 py-2 rounded-[25px] cursor-pointer mt-5 "
        onClick={() => handelPostJob()}
      >
        POST JOB
      </button>
    </div>
  );
}

export default JobUpdate;
